import python from "../assets/images/Python-Symbol.png";
import javascript from "../assets/images/javascript-logo-javascript-icon-transparent-free-png.png";
import php from "../assets/images/php-1-logo-png-transparent.png";
import css from "../assets/images/CSS-png.png";
import sass from "../assets/images/sass.png";
import mysql from "../assets/images/1024px-MySQL.ff87215b43fd7292af172e2a5d9b844217262571.png";
import html from "../assets/images/6024bc5746d7436c727825dc4fc23c22-html-programming-language-icon.png";
import knex from "../assets/images/knex-logo.png";
import nginx from "../assets/images/Nginx-Logo.wine.png";
import react from "../assets/images/react.png";

import "./TechStack.scss";

import {useTranslation} from "react-i18next";

export default function TechStack() {

    const { i18n, t } = useTranslation();
    
    return <div className="main">
        
            <p className="desktop">{t("tech_desktop")}</p>
            <p className="mobile">{t("tech_mobile")}</p>

            <div className="col-to-rows">

                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="flip-card-image" src={python} alt="Python Logo" />
                        </div>
                        <div className="flip-card-back">
                            <h2>Python</h2>
                            <p>{t("tech_python")}<a rel="noreferrer" href='https://www.youtube.com/watch?v=-3-oCUMe9pQ' target='_blank'>{t("tech_python_l1")}</a>.</p>
                        </div>
                    </div>
                </div> 

                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="flip-card-image" src={react} alt="React Logo Atom" />
                        </div>
                        <div className="flip-card-back">
                            <h2>React</h2>
                            <p>{t("tech_react")}</p>
                        </div>
                    </div>
                </div>

                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="flip-card-image" src={javascript} alt="JavaScript Logo" />
                        </div>
                        <div className="flip-card-back">
                            <h2>JavaScript</h2>
                            <p>{t("tech_js")}</p>
                        </div>
                    </div>
                </div>

                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="flip-card-image" src={css} alt="" />
                        </div>
                        <div className="flip-card-back">
                            <h2>CSS</h2>
                            <p>{t("tech_css")}</p>
                        </div>
                    </div>
                </div>

                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="flip-card-image" src={php} alt=""/>
                        </div>
                        <div className="flip-card-back">
                            <h2>PHP</h2>
                            <p>{t("tech_php")}</p>
                        </div>
                    </div>
                </div>

                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="flip-card-image" src={mysql} alt="" />
                        </div>
                        <div className="flip-card-back">
                            <h2>SQL and MySQL</h2>
                            <p>{t("tech_sql")}</p>
                        </div>
                    </div>
                </div>

                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="flip-card-image" src={knex} alt = ""/>
                        </div>
                        <div className="flip-card-back">
                            <h2>Knex</h2>
                            <p>{t("tech_knex")}</p>
                        </div>
                    </div>
                </div>

                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="flip-card-image" src={sass} alt="" />
                        </div>
                        <div className="flip-card-back">
                            <h2>Sass</h2>
                            <p>{t("tech_sass")}</p>
                        </div>
                    </div>
                </div>

                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="flip-card-image" src={html} alt="" />
                        </div>
                        <div className="flip-card-back">
                            <h2>HTML</h2>
                            <p>{t("tech_html")}</p>
                        </div>
                    </div>
                </div>

                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="flip-card-image" src={nginx} alt="" />
                        </div>
                        <div className="flip-card-back">
                            <h2>Nginx</h2>
                            <p>{t("tech_nginx")}</p>
                        </div>
                    </div>
                </div>

            </div></div>
}