

import "./Menu.scss";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import hamburgerIcon from "../assets/icons/hamburger-menu.png"

import linkedin from "../assets/icons/LinkedIn_icon.svg.png";
import github from "../assets/icons/pngimg.com - github_PNG28.png";
import mail from "../assets/icons/mail.png";
import phone from "../assets/icons/phone-png-3.png";

import { LANGUAGES } from "../constants/index.ts";
import {useTranslation} from "react-i18next";

export default function Menu() {
    const [showHamburger, setShowHamburger] = useState(false);

    const { i18n, t } = useTranslation();

    const onChangeLang = (e) => {
        const lang_code = e.target.value;
        i18n.changeLanguage(lang_code);
    }

    const handleClickHamburger= (event) => {
        setShowHamburger(!showHamburger)
    }

    return <div className="nav">

        

        <HamburgerMenu setShowHamburger={setShowHamburger} handleClickHamburger={handleClickHamburger} showHamburger={showHamburger} />

        <div className="nav__logo">
            <div className="nav__logo_title">
                <NavLink className="nav__logo_title" to="/">EJLR</NavLink>
            </div>
            <div className="nav__logo_tagline">
                <NavLink className="nav__logo_tagline" to="/">Web Developer</NavLink>
            </div>
        </div>

        <div className="nav__menu">
            <NavLink className="nav__link" to="/projects">{t("projects")}</NavLink>
            <NavLink className="nav__link" to="/about">{t("about")}</NavLink>
            <NavLink className="nav__link" to="/techstack">{t("techStack")}</NavLink>
            <NavLink className="nav__link" to="/resume">{t("resume")}</NavLink>

            <select className="lang-switcher" defaultValue={"en"} onChange={onChangeLang}>
                {LANGUAGES.map(({ code, label }) => (
                    <option key={code} value={code}>
                        {label}
                    </option>
                ))}
            </select>
        </div>

        <div className="nav__contact">
            <NavLink className="nav__social" to="https://www.linkedin.com/in/eric-jl-richard/" target="_blank" alt="LinkedIn">
                <img className="nav__social-icon" src={linkedin}></img>
            </NavLink> 
            <NavLink className="nav__social" to="https://github.com/ericjlrichard" target="_blank" alt="GitHub">
                <img className="nav__social-icon" src={github}></img>
            </NavLink> 
            <NavLink className="nav__social" to="mailto:ericjlrichard@gmail.com" target="_blank" alt="ericjlrichard@gmail.com">
                <img className="nav__social-icon" src={mail}></img>
            </NavLink> 
            <NavLink className="nav__social" to="tel:+15148064121" target="_blank" alt="+1-514-806-4121">
                <img className="nav__social-icon" src={phone}></img>
            </NavLink> 

        </div>

        <img className="nav__hamburger-icon" alt="Top Right Menu" src={hamburgerIcon} onClick={handleClickHamburger}/>
    </div>
}