
import "./Resume.scss";

import { NavLink } from "react-router-dom";

import {useTranslation} from "react-i18next";

export default function Resume() {

    const { i18n, t } = useTranslation();

    return <div className="resumemain">
                <h1>{t("resume")}</h1>


                <p className="paragraph-resume">
                {t("resume1_a")} <a className="inline-link" href="../assets/docs/EricRichard-Resume-FR.pdf" target="_blank">{t("resume1_l1")}</a> {t("resume1_b")} <a className="inline-link" href="../assets/docs/EricRichard-Resume-EN.pdf" target="_blank">{t("resume1_l2")}</a>{t("resume1_c")}
                </p>

                <h2>{t("resume2_title")}</h2>

                <p className="paragraph-resume">{t("resume2_a")} <NavLink to="/techstack">Tech Stack</NavLink>!
                
                <ul>
                    <li>{t("resume2_b")}</li>
                    <li>{t("resume2_c")}</li>
                    <li>{t("resume2_d")}</li>
                    <li>{t("resume2_e")}</li>
                    <li>{t("resume2_f")}</li>
                </ul>
                </p>

                <h2>{t("resume3_title")}</h2>

                <p className="paragraph-resume">
                {t("resume3_a")}
                    <ul>
                        <li><NavLink className="inline-link" to="https://www.thefractionaledge.com" target="_blank">{t("resume3_b")}</NavLink> {t("resume3_b_desc")}</li>
                        <li><NavLink className="inline-link" to="https://www.calbalclassic.com" target="_blank">{t("resume3_c")}</NavLink> {t("resume3_c_desc")}</li>
                        <li><NavLink className="inline-link" to="https://www.laremise.ca" target="_blank">{t("resume3_d")}</NavLink> {t("resume3_d_desc")}</li>
                        <li><NavLink className="inline-link" to="https://www.coreenergetics.ca" target="_blank">{t("resume3_e")}</NavLink> {t("resume3_e_desc")}</li>
                        <li><NavLink className="inline-link" to="https://aidanfisher.com" target="_blank">{t("resume3_f")}</NavLink> {t("resume3_f_desc")}</li>
                        <li><NavLink className="inline-link" to="https://www.studio88swing.com" target="_blank">Studio 88 Swing</NavLink> (2004) & <NavLink className="inline-link" to="https://www.swingconnexion.com" target="_blank">Swing ConneXion</NavLink> {t("resume3_g_desc")}</li>
                    </ul>

                    <p className="paragraph-resume">
                        
                    </p>
                </p>

                
                <h2>{t("resume4_title")}</h2>

                <p className="paragraph-resume">
                    {t("resume4_a")}
                </p>

                <p className="paragraph-resume">
                {t("resume4_b")}
                </p>

                <h2>{t("resume5_title")}</h2>

                <p className="paragraph-resume">{t("resume5_a")}</p>

                <h2>{t("resume6_title")}</h2>

                <p className="paragraph-resume">
                {t("resume6_a")}
                    <ul>
                        <li>{t("resume6_b")}</li>
                        <li>{t("resume6_c")}</li>
                        <li>{t("resume6_d")}</li>
                        <li>{t("resume6_e")}</li>
                    </ul>
                </p>

            </div>
}