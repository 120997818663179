import "./HamburgerMenu.scss"
import { Link, NavLink } from "react-router-dom";

import { LANGUAGES } from "../../constants/index.ts";
import {useTranslation} from "react-i18next";


export default function HamburgerMenu({showHamburger, setShowHamburger, handleHamburgerClick}) {

  const { i18n, t } = useTranslation();

  if (!showHamburger) {
    return null;
  }

  //not my fave thing, but passing handleHamburgerClick didn't seem to work.
  const handleOnClick = (event) => {
    setShowHamburger(false);
  }

  return (
    <div className="hamburger__menu">
      
      <NavLink className="hamburger__link" to="/projects" onClick={handleOnClick}>{t("projects")}</NavLink>
      <NavLink className="hamburger__link" to="/about" onClick={handleOnClick}>{t("about")}</NavLink>
      <NavLink className="hamburger__link" to="/techstack" onClick={handleOnClick}>{t("techStack")}</NavLink>
      {/*<div className="hamburger__divider"></div>*/}
      
      <NavLink className="hamburger__link" to="/resume" onClick={handleOnClick}>{t("resume")}</NavLink>
    </div>
  )
}