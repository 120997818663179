import linkedin from "../assets/icons/LinkedIn_icon.svg.png";
import github from "../assets/icons/pngimg.com - github_PNG28.png";
import mail from "../assets/icons/mail.png";
import phone from "../assets/icons/phone-png-3.png";

import {NavLink} from "react-router-dom";
import "./Footer.scss";

export default function Footer() {
    return <div>

        <div className="footernav__contact">

            <NavLink className="footernav__social" to="https://www.linkedin.com/in/eric-jl-richard/" target="_blank" alt="LinkedIn">
                <img className="footernav__social-icon" src={linkedin}></img>
            </NavLink> 
            <NavLink className="footernav__social" to="https://github.com/ericjlrichard" target="_blank" alt="GitHub">
                <img className="footernav__social-icon" src={github}></img>
            </NavLink> 
            <NavLink className="footernav__social" to="mailto:ericjlrichard@gmail.com" target="_blank" alt="ericjlrichard@gmail.com">
                <img className="footernav__social-icon" src={mail}></img>
            </NavLink> 
            <NavLink className="footernav__social" to="tel:+15148064121" target="_blank" alt="+1-514-806-4121">
                <img className="footernav__social-icon" src={phone}></img>
            </NavLink> 

        </div>


    </div>
}