import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false
    },
    resources: {
        en: {
            translation: {
                title: "Eric JL Richard - Web Developer",
                logoTitle: "EJLR",
                logoSubTitle: "Web Developer",
                about: "About",
                techStack: "Tech Stack",
                resume: "Resume",
                projects: "Projects",
                home: "Home",
                aboutTitle: "More About Me",
                
                home1: "I always strongly believed in technology helping to make a better world. I’ve been programming since I was 9, and kept on doing it ever since! I take to programming the same discipline and eye for detail I instilled in my dance career for 20 years.",
                home2_a: 'While in my early teens I was mostly self-taught, I have a three-year degree in programming, and more recently completed a Web Development bootcamp. ',
                home2_l1: "Read more about me here",
                home2_b: ", check out ",
                home2_l2: "a few projects I did",
                home2_c: " or ",
                home2_l3: "jump directly to my resume",
                home3_a: 'I am proficient in many markup and programming languages (HTML, CSS, Sass, JavaScript, PHP, Python). You can ',
                home3_l1: 'check out my Tech Stack here',
                home3_b: "!",
                home4_a: "After completing many contracts, big and small, over 2023, I'm currently looking for work. Interested in my skills?",
                home4_l1: "Drop me a line",

                projects_abc: "Angelo Boxing Coach",
                projects_fun_fact: "Fun Fact",
                projects_quordle: "Quordle - The Movie Quotes Guessing Game",
                
                projects1_a: "Meet",
                projects1_b: ", the ultimate app transforming your boxing regimen. Elevate your training with random combos, keeping workouts dynamic. Embrace the tactics of legends like Tyson, Pacquiao, Mayweather and Canelo for a comprehensive skill boost. Whether you favor an orthodox or southpaw stance, this app caters to your style. Enjoy the flexibility of choosing workout times that suit your schedule seamlessly. Unleash your full boxing potential with Angelo Boxing Coach – where legendary techniques and personalized training converge, bringing the spirit of champions to your fingertips.",
                projects1_c: "Sass, CSS, JavaScript, React, Knex and MySQL (original app had an API)",
                projects1_d: "Transcribing actual boxers combos and making all of it make sense on a 2D screen was definitely a challenge with which I tinkered quite a bit. Animation was also not something I had done a lot. However, trying a workout and confirming that the app actually succeeded in some solid exercise was really satisfactory!",
                projects1_e: "Although I always liked the sport for its historical and technical aspects, I got more interested in boxing through an unusual medium: during the pandemic, my friend lent me their Oculus Quest and I discovered the game Thrill of the Fight.",

                projects2_a: "Enter the captivating world of ",
                projects2_b: " that challenges your cinematic knowledge. Boasting 30 iconic quotes from classic films, the app immerses you in a thrilling guessing experience. With just six attempts, you must decipher details such as the movie name, actor, and character. Feeling stuck? Opt to unveil an additional hint – a one-second snippet of the quote – to guide your guess. Quordle offers a delightful blend of nostalgia and challenge, making it a must-have for movie enthusiasts eager to test their expertise in a fun and interactive way. ",
                projects2_c: "Sass, CSS, JavaScript, React, Axios (original app had an API)",
                projects2_d: "The biggest hurdle in this project was that everything audio in React was new to me at the time. Finding good sounding clips, as you'll notice, was also a challenge, even with my years of experience in editing and cutting songs for dance performances. As a movie enthusiast however, it was really fun to do the research part.",
                projects2_e: "The first version of this app was produced in only two days with a colleague during my BrainStation bootcamp! I tweaked the audios a bit with Audacity to make them easier to guess, changed a few things around and got rid of the API, but it's mostly as it was presented.",

                about_title: "More About Me",
                about_head: "Here are a few interesting facts about me! I'll lead with this: I programmed this carousel from scratch with CSS and JavaScript. Don't worry it gets more interesting.",
                about1_title: "Music makes the world go 'round!",
                about1_desc_a: "I play a few instruments (piano, guitar, drums), I've even programmed an",
                about1_l1: "app in Python and PyQt5",
                about1_desc_b: "to organize my music tabs!",
                about2_title: "Zack who?",
                about2_desc: "I go by Zack in most of my social interactions, which seems curious at first. I kept on singing Zachary Richard songs in high school and the nickname stuck!",
                about3_title: "Proud father!",
                about3_desc: "My daughter is one of the greatest people I know. She's a filmmaker and aspiring teacher, and brings me so much joy!",
                about4_title: "Dance like everyone's watching",
                about4_desc: "I'm more than an 'enthusiastic' dancer. In fact, I've taught and performed swing dance internationally and won major competitions for more than 20 years now!",
                about5_title: "What doesn't kill me gets me XP",
                about5_desc: "Like a lot of programmers and developpers, I'm an avid gamer. My favorite is definitely Kerbal Space Program! Here is a picture of me in a Star Trek t-shirt, cementing my geek cred.",

                resume1_a: "You can download my one-page resume in",
                resume1_l1: "French",
                resume1_b: "or",
                resume1_l2: "English",
                resume1_c: "! Or for a more detailed account of my experience, keep scrolling.",

                resume2_title: "Languages and Tools",
                resume2_a: "Here is a list of languages and programming tools I'm familiar with. You can also see those in my",
                resume2_b: "JavaScript, TypeScript, Python, C++, PHP: If I had to choose a preferred language I'd have to say Python, but I mostly work with JavaScript or PHP on the daily.",
                resume2_c: "HTML, CSS and Sass: Markup and styling languages are not seen as programming by some, but nonetheless are vital!",
                resume2_d: "WordPress, Wix: While I usually prefer to work from scratch, it's important to know how those function. Especially Wordpress which is very prevalent. They also have pretty good SEO tools and plugins to help you with aspects you might be less familiar with.",
                resume2_e: "PhotoShop, Audacity, GIMP 2.0, Lightworks: I've created my fair share of content for my dance studio, so had to be familiar with a lot of different tools to edit audio, video and graphics.",
                resume2_f: "Knex, Axios: I really enjoy programming APIs, and those are two good tools for it.",

                resume3_title: "Websites",
                resume3_a: "I helped numerous organizations either update their websites or create them for scratch. Most had very specific needs that I assisted in addressing.",
                resume3_b: "The Fractional Edge",
                resume3_b_desc: "(2023): Pretty boilerplate website in WordPress, the biggest challenge was to accomodate their particular needs with a certain YouTube plugin.",
                resume3_c: "California Balboa Classic",
                resume3_c_desc: "(2023): This was mostly aesthetics, as the website was dated and needed a new look.",
                resume3_d: "La Remise",
                resume3_d_desc: "(2023): A WordPress website with many dependencies, inter-connecting platforms (MyTurn, Square, ARMember) custom plugins, and mixes of languages (Python, PHP, JQuery, JavaScript). Definitely a challenging tapestry to navigate!",
                resume3_e: "Core Energetics",
                resume3_e_desc: "(2020): A WordPress website with several components for a self-help organization.",
                resume3_f: "Aidan Fisher",
                resume3_f_desc: "(2020): The personal website of the director of Core Energetics, we had to make several links between the two.",
                resume3_g_desc: "(2010): Obviously it's not what is in use right now, but I built something similar - the registation and schedule system.",
                resume3_h: "For most of those, we used WordPress and a variety of plugins and custom tools in different languages, PHP being the most predominant one.",


                resume4_title: "BrainStation BootCamp",
                resume4_a: "In the fall of 2022 I successfully completed a three-month web development bootcamp at BrainStation. It finally gave me the confidence to explore going back to programming full time, something I had postponed for many years.",
                resume4_b: "We worked mainly in React with HTML, CSS, JavaScript and TypeScript, and explored different tools: Knex, Sass, Axios, MySQL, among others.",
                resume4_c: "",
                resume4_d: "",
                resume4_e: "",
                resume4_f: "",

                resume5_title: "Tutoring and Teaching",
                resume5_a: "I love teaching, and was recently lucky enough to tutor a university engineering student in Python who was struggling with their programming class (spoiler alert: they succeeded and passed the class with flying colors in the end!). I've been teaching dance for 20 years and I year I'm very good at it, so putting those two passions together makes a lot of sense for me!",
                resume5_b: "",
                resume5_c: "",
                resume5_d: "",
                resume5_e: "",
                resume5_f: "",

                resume6_title: "Dancing and Coaching",
                resume6_a: "Dancing has been a major part of my life, and my main job for the past two decades. While I had my degree in computer programming, it soon became obvious that I could make a living dancing and teaching, something I started to do at 21. Here are a few highlights!",
                resume6_b: "Dancing and coaching helped me develop my social and public speaking skills. I soon was able to MC events in front of hundreds of people - not bad for someone who had to rehearse mentally before buying gum at the store!",
                resume6_c: "Being hired to teach abroad has given me the incredible opportunity to travel. I've been to England, France, the US, Australia, Germany, Sweden, Italy, Russia, Spain - every trip giving me invaluable insights into other cultures and places.",
                resume6_d: "As a coach, I have learnt to be empathetic with my students and go to the root of their issues - a lot of psychology is involved when it involves moving one's body.",
                resume6_e: "The ability to break down concepts and explain them clearly has helped me in all aspects of my life.",
                resume6_f: "",

                tech_desktop: "Hover to animate the flip cards!",
                tech_mobile: "Click to animate the flip cards!",
                tech_python: "I tutored students in need in Python! I also programmed a music tab manager that you can",
                tech_python_l1: "see in action here",
                tech_js: "JS is the bread and butter of web development. See my projects for more!",
                tech_css: "I used to profoundly dislike CSS but the updates in the last years made it much more intuitive to use. This flip card animation is done exclusively through CSS!",
                tech_php: "I worked with PHP mainly for the Swing ConneXion website, and all the websites I did with Wordpress.",
                tech_sql: "Whenever I can, I use SQL for all my database needs...",
                tech_knex: "...And Knex is a great tool to build a SQL-based API!",
                tech_sass: "I love the shortcuts and supplements that Sass provides to CSS, and use it whenever possible.",
                tech_html: "The mark-up language par excellence",
                tech_nginx: "This website is supported by the Nginx workframe. Implementing my own server taught me a lot of important skills!",
                tech_react: "For some of my React apps, see the Projects section!",
                
            }
        },

        fr: {
            translation: {
                title: "Eric JL Richard - Développeur Web",
                logoTitle: "EJLR",
                logoSubTitle: "Développeur Web",
                about: "À Propos",
                techStack: "Tech Stack",
                resume: "CV",
                projects: "Projets",
                home: "Accueil",

                home1: "J'ai toujours cru fermement que la technologie peut rendre le monde meilleur. Je programme depuis l'âge de 9 ans, et ai continué depuis! J'applique à la programmation la même discipline et le même souci du détail que j'ai instillé dans ma carrière en danse durant les 20 dernières années.",
                home2_a: "Autodidacte dans mon adolescence, j'ai complété un DET en informatique, et plus récemment accepté l'aventure d'un bootcamp en développement web. ",
                home2_l1: "Apprenez en plus sur moi ici",
                home2_b: ", consultez ",
                home2_l2: "quelques projets que j'ai accomplis",
                home2_c: " ou ",
                home2_l3: "bondissez directement à mon C.V.",
                home3_a: "Je suis à l'aise dans plusieurs languages de programmation et de markup (HTML, CSS, Sass, JavaScript, PHP, Python). Vous pouvez ",
                home3_l1: 'consulter ma pile technologique (Tech Stack) ici',
                home3_b: "!",
                home4_a: "Après avoir complété plusieurs contrats, petits et grands, en 2023, je suis maintenant à la recherche d'emplois. Intéressés par mes compétences?",
                home4_l1: "Écrivez-moi",

                projects_abc: "Angelo Boxing Coach",
                projects_fun_fact: "Anecdote",
                projects_quordle: "Quordle - Devinez La Citation",

                projects1_a: "Découvrez",
                projects1_b: ", l'application ultime qui transforme votre régime d'entraînement de boxe. Élevez votre entraînement avec des combinaisons aléatoires, rendant vos séances dynamiques. Adoptez les tactiques des légendes comme Tyson, Pacquiao, Mayweather et Canelo pour booster vos compétences de manière globale. Que vous préfériez une posture orthodoxe ou southpaw, cette application s'adapte à votre style. Profitez de la flexibilité pour choisir des horaires d'entraînement qui conviennent à votre emploi du temps. Libérez votre plein potentiel de boxeur avec Angelo Boxing Coach - où les techniques légendaires et l'entraînement personnalisé convergent, apportant l'esprit des champions à portée de main.",
                projects1_c: "Sass, CSS, JavaScript, React, Knex et MySQL (l'application originale disposait d'une API)",
                projects1_d: "Transcrire les combos réels des boxeurs et faire en sorte que tout cela ait du sens sur un écran 2D a certainement été un défi sur lequel j'ai beaucoup travaillé. L'animation n'était pas non plus quelque chose que j'avais beaucoup pratiquée. Cependant, essayer un entraînement et confirmer que l'application réussissait effectivement à offrir un exercice solide était vraiment satisfaisant !",
                projects1_e: "Bien que j'aie toujours aimé ce sport pour ses aspects historiques, je me suis davantage intéressé à la boxe à travers un moyen inhabituel : pendant la pandémie, un ami m'a prêté son Oculus Quest et j'ai découvert le jeu Thrill of the Fight.",

                projects2_a: "Plongez dans le monde captivant de ",
                projects2_b: " qui met à l'épreuve vos connaissances cinématographiques. Avec 30 citations emblématiques de films classiques, l'application vous plonge dans une expérience de devinette palpitante. En seulement six tentatives, vous devez décrypter des détails tels que le nom du film, l'acteur et le personnage. Vous vous sentez bloqué ? Choisissez de dévoiler un indice supplémentaire - un extrait d'une seconde de la citation - pour guider votre conjecture. Quordle offre un mélange délicieux de nostalgie et de défi, en faisant un incontournable pour les amateurs de cinéma désireux de tester leur expertise de manière amusante et interactive. ",
                projects2_c: "Sass, CSS, JavaScript, React, Axios (l'application originale disposait d'une API)",
                projects2_d: "Le plus grand défi de ce projet était que tout ce qui concerne l'audio en React était nouveau pour moi à l'époque. Trouver des extraits sonores de qualité, comme vous le remarquerez, était également un défi, même avec mes années d'expérience dans l'édition et le découpage de chansons pour des spectacles de danse. En tant qu'enthousiaste de cinéma cependant, la partie recherche était vraiment amusante.",
                projects2_e: "La première version de cette application a été produite en seulement deux jours avec un collègue lors de mon bootcamp BrainStation ! J'ai ajusté un peu les extraits sonores avec Audacity pour les rendre plus faciles à deviner, changé quelques éléments et supprimé l'API, mais c'est principalement tel qu'il a été présenté.",

                about_title: "À Propos",
                about_head: "Voici quelques faits intéressants à mon propos. Pour commencer, j'ai programmé ce petit carousel en CSS et JS de zéro, mais ne vous inquiétez pas ça devient plus intéressant!",
                about1_title: "La musique adoucit les moeurs!",
                about1_desc_a: "J'ai à mon répertoire quelques intruments (piano, guitare, batterie), j'ai même programmé une",
                about1_l1: "application en Python et PyQt5",
                about1_desc_b: "pour organiser mes tablatures!",
                about2_title: "Zack qui?",
                about2_desc: "Socialement, tout le monde m'appelle Zack et c'est généralement ainsi que je me présente. Je n'avais de cesse de chanter du Zachary Richard au secondaire, et le sobriquet a tenu!",
                about3_title: "Un fier papa",
                about3_desc: "Ma fille est une des personnes les plus extraordinaires que je connais. Cinéaste et future enseignante, elle m'apporte de la joie chaque jour!",
                about4_title: "Dansez comme si tout le monde regardait",
                about4_desc: "Je suis un danseur plus que 'motivé'. J'ai en fait enseigné et performé en danse swing internationalement, et remporté des compétitions majeures au cours des 20 dernières années.",
                about5_title: "Ce qui ne me tue pas me donne du XP",
                about5_desc: "Comme beaucoup de programmeurs et développeurs, j'adore les jeux vidéos. Mon préféré est définitivement Kerbal Space Program! Voici une photo de mon t-shirt de Star Trek pour cimenter ma réputation de geek.",

                resume1_a: "Vous pouvez télécharger mon CV d'une page en",
                resume1_l1: "français",
                resume1_b: "ou",
                resume1_l2: "anglais",
                resume1_c: "! Ou pour un compte rendu plus détaillé de mon expérience, continuez à faire défiler.",

                resume2_title: "Langues et Outils",
                resume2_a: "Voici une liste des langues et des outils de programmation avec lesquels je suis familier. Vous pouvez également les voir dans mon",
                resume2_b: "JavaScript, TypeScript, Python, C++, PHP : Si je devais choisir une langue préférée, je dirais Python, mais je travaille principalement avec JavaScript ou PHP au quotidien.",
                resume2_c: "HTML, CSS et Sass : Les langages de balisage et de style ne sont pas considérés comme de la programmation par certains, mais ils sont néanmoins essentiels !",
                resume2_d: "WordPress, Wix : Bien que je préfère généralement travailler à partir de zéro, il est important de savoir comment ceux-ci fonctionnent. Surtout WordPress qui est très répandu. Ils ont aussi de très bons outils SEO et des plugins pour vous aider avec des aspects avec lesquels vous pourriez être moins familier.",
                resume2_e: "PhotoShop, Audacity, GIMP 2.0, Lightworks : J'ai créé ma juste part de contenu pour mon studio de danse, donc j'ai dû être familier avec de nombreux outils différents pour éditer l'audio, la vidéo et les graphiques.",
                resume2_f: "Knex, Axios : J'aime vraiment programmer des API, et ce sont deux bons outils pour cela.",
    
                resume3_title: "Sites Web",
                resume3_a: "J'ai aidé de nombreuses organisations à mettre à jour leurs sites web ou à les créer à partir de zéro. La plupart avaient des besoins très spécifiques que j'ai aidé à résoudre.",
                resume3_b: "The Fractional Edge",
                resume3_b_desc: "(2023) : Site web assez standard sur WordPress, le plus grand défi a été d'accommoder leurs besoins particuliers avec un certain plugin YouTube.",
                resume3_c: "California Balboa Classic",
                resume3_c_desc: "(2023) : Il s'agissait principalement d'esthétique, car le site web était démodé et avait besoin d'un nouveau look.",
                resume3_d: "La Remise",
                resume3_d_desc: "(2023) : Un site web WordPress avec de nombreuses dépendances, des plateformes interconnectées (MyTurn, Square, ARMember), des plugins personnalisés et des mélanges de langages (Python, PHP, JQuery, JavaScript). Certainement une tapisserie complexe à naviguer !",
                resume3_e: "Core Energetics",
                resume3_e_desc: "(2020) : Un site web WordPress avec plusieurs composants pour une organisation d'auto-assistance.",
                resume3_f: "Aidan Fisher",
                resume3_f_desc: "(2020) : Le site web personnel du directeur de Core Energetics, nous avons dû créer plusieurs liens entre les deux.",
                resume3_g_desc: "(2010) : Évidemment, ce n'est pas ce qui est utilisé actuellement, mais j'ai construit quelque chose de similaire - le système d'inscription et de planification.",
                resume3_h: "Pour la plupart de ces projets, nous avons utilisé WordPress et une variété de plugins et d'outils personnalisés dans différentes langues, le PHP étant la plus prédominante.",
    
                resume4_title: "BootCamp BrainStation",
                resume4_a: "À l'automne 2022, j'ai réussi à terminer avec succès un bootcamp de développement web de trois mois chez BrainStation. Cela m'a enfin donné la confiance nécessaire pour envisager de revenir à la programmation à plein temps, quelque chose que j'avais repoussé pendant de nombreuses années.",
                resume4_b: "Nous avons principalement travaillé avec React, HTML, CSS, JavaScript et TypeScript, et exploré différents outils : Knex, Sass, Axios, MySQL, entre autres.",
                resume4_c: "",
                resume4_d: "",
                resume4_e: "",
                resume4_f: "",
    
                resume5_title: "Tutorat et Enseignement",
                resume5_a: "J'adore enseigner, et j'ai récemment eu la chance de donner des cours particuliers à un étudiant en ingénierie à l'université en Python qui éprouvait des difficultés avec son cours de programmation (spoiler : il a réussi et a passé son cours avec brio à la fin !). J'enseigne la danse depuis 20 ans et je suis très doué, donc combiner ces deux passions a beaucoup de sens pour moi !",
                resume5_b: "",
                resume5_c: "",
                resume5_d: "",
                resume5_e: "",
                resume5_f: "",
    
                resume6_title: "Danse et Coaching",
                resume6_a: "La danse a été une partie importante de ma vie et mon principal métier depuis deux décennies. Bien que j'aie mon diplôme en programmation informatique, il est rapidement devenu évident que je pouvais gagner ma vie en dansant et en enseignant, quelque chose que j'ai commencé à faire à 21 ans. Voici quelques faits saillants !",
                resume6_b: "La danse et l'enseignement m'ont aidé à développer mes compétences sociales et d'élocution en public. J'ai bientôt été capable d'animer des événements devant des centaines de personnes - pas mal pour quelqu'un qui devait répéter mentalement avant d'acheter du chewing-gum au magasin !",
                resume6_c: "Être embauché pour enseigner à l'étranger m'a donné l'incroyable opportunité de voyager. J'ai été en Angleterre, en France, aux États-Unis, en Australie, en Allemagne, en Suède, en Italie, en Russie, en Espagne - chaque voyage me donnant des informations inestimables sur d'autres cultures et endroits.",
                resume6_d: "En tant que coach, j'ai appris à faire preuve d'empathie envers mes élèves et à aller à la racine de leurs problèmes - beaucoup de psychologie est impliquée lorsqu'il s'agit de faire bouger son corps.",
                resume6_e: "La capacité à décomposer des concepts et à les expliquer clairement m'a aidé dans plusieurs aspects de ma vie personnelle et professionnelle, particulièrement en tutorat et pour expliquer des concepts à des clients moins familiers avec les diverses technologies.",

                tech_desktop: "Survolez pour animer les cartes retournables!",
                tech_mobile: "Cliquez pour animer les cartes retournables!",
                tech_python: "J'ai donné des cours particuliers de Python aux étudiants en difficulté ! J'ai également programmé un gestionnaire d'onglets musicaux que vous pouvez",
                tech_python_l1: "voir en action ici",
                tech_js: "JS est le pain quotidien du développement web. Consultez mes projets pour en savoir plus !",
                tech_css: "Je détestais profondément le CSS, mais les mises à jour de ces dernières années l'ont rendu beaucoup plus intuitif à utiliser. Cette animation de carte retournable est réalisée exclusivement en CSS !",
                tech_php: "J'ai principalement travaillé avec PHP pour le site Swing ConneXion, ainsi que pour tous les sites que j'ai réalisés avec Wordpress.",
                tech_sql: "Chaque fois que c'est possible, j'utilise SQL pour tous mes besoins en matière de base de données...",
                tech_knex: "...Et Knex est un excellent outil pour construire une API basée en SQL !",
                tech_sass: "J'adore les raccourcis et les compléments que Sass apporte au CSS, et je l'utilise chaque fois que possible.",
                tech_html: "Le langage de balisage par excellence",
                tech_nginx: "Ce site web est pris en charge par le framework Nginx. Mettre en place mon propre serveur m'a appris beaucoup de compétences importantes !",
                tech_react: "Pour un échantillon de mes applications React, consultez la section Projets !",
    
            }
        }
    }
});

export default i18n;