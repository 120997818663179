import logo from './logo.svg';
import './App.css';

import Home from "./pages/Home.js";
import TechStack from "./pages/TechStack.js";
import Resume from "./pages/Resume.js";
import Contact from "./pages/Contact.js";
import Projects from "./pages/Projects.js";
import About from "./pages/About.js";

import Menu from "./components/Menu.js";
import Footer from "./components/Footer.js";

import { HashRouter} from "react-router-dom";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./i18n.ts";

function App() {
  return (
    <div className="App">
      <HashRouter>

        <Menu />

        <Routes>
          <Route path="/" element={<Home  />} />
          <Route path="/TechStack" element={<TechStack  />} />
          <Route path="/About" element={<About  />} />
          <Route path="/Projects" element={<Projects  />} />
          <Route path="/Resume" element={<Resume  />} />
        </Routes>

        <Footer />
        
      </HashRouter>
    </div>
  );
}

export default App;
