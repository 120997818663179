import gaming from "../assets/images/startrek.jpg";
import kid from "../assets/images/kid.jpg";
import dancing from "../assets/images/dancing.jpg";
import music2 from "../assets/images/music2.jpg";
import zack from "../assets/images/zack.jpg";
import arrow from "../assets/icons/arrow-left.png";

import {NavLink} from "react-router-dom";
import {useState, useEffect} from "react";

import "./About.scss";

import {useTranslation} from "react-i18next";

export default function About() {
    let [imageIndex, setImageIndex] = useState(0);

    const { i18n, t } = useTranslation();

    const arrowClick = (e) => {
        const arrow = e.target.id;

        const cards = window.document.getElementsByClassName("about__card");

        if (arrow == "arrow-left") {
            imageIndex > 0 ? setImageIndex(imageIndex - 1) : setImageIndex(cards.length -1)
        }
        else {
            imageIndex == (cards.length -1) ? setImageIndex(0) : setImageIndex(imageIndex + 1);
        }

        for( let i = 0; i < cards.length; i++) {
            cards[i].classList.add("fade-out");
        }

        cards[imageIndex].classList.remove("fade-out");
        cards[imageIndex].classList.add("fade-in");
    }

    return <div className="main">
                <h1>{t("about_title")}</h1>

                <p>
                {t("about_head")}
                </p>

                <div className="carousel">

                <div id="arrow-left" className="carousel__arrow" onClick={arrowClick}>
                    <img src={arrow} className="carousel__arrow-left"></img>
                </div>

                <div className="about__card-container">

                    <div className="about__card">
                        <div className="about__card-title">
                            {t("about1_title")}
                        </div>
                        <div className="about__card-image-container">
                            <img src={music2} className="about__card-image"></img>
                        </div>
                        <div className="about__card-description">
                            {t("about1_desc_a")} <NavLink to="https://www.youtube.com/watch?v=-3-oCUMe9pQ" target="_blank">{t("about1_l1")}</NavLink> {t("about1_desc_b")}
                        </div>
                    </div>

                    <div className="about__card">
                        <div className="about__card-title">
                            {t("about2_title")}
                        </div>
                        <div className="about__card-image-container">
                            <img src={zack} className="about__card-image"></img>
                        </div>
                        <div className="about__card-description">
                            {t("about2_desc")}
                        </div>
                    </div>

                    <div className="about__card">
                        <div className="about__card-title">
                            {t("about3_title")}
                        </div>
                        <div className="about__card-image-container">
                            <img src={kid} className="about__card-image"></img>
                        </div>
                        <div className="about__card-description">
                            {t("about3_desc")}
                        </div>
                    </div>

                    <div className="about__card">
                        <div className="about__card-title">
                            {t("about4_title")}
                        </div>
                        <div className="about__card-image-container">
                            <img src={dancing} className="about__card-image"></img>
                        </div>
                        <div className="about__card-description">
                            {t("about4_desc")}
                        </div>
                    </div>

                    <div className="about__card">
                        <div className="about__card-title">
                            {t("about5_title")}
                        </div>
                        <div className="about__card-image-container">
                            <img src={gaming} className="about__card-image"></img>
                        </div>
                        <div className="about__card-description">
                            {t("about5_desc")}
                        </div>
                    </div>

                </div>

                <div  id="arrow-right" className="carousel__arrow" onClick={arrowClick}>
                        <img src={arrow} className="carousel__arrow-right"></img>
                </div>

                </div>              

            </div>
}