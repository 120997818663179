
import "./Projects.scss";

import abcScreenshot from "../assets/images/abc-screenshot.png";
import quordleScreenshot from "../assets/images/quordle_screenshot.png";

import {useTranslation} from "react-i18next";

export default function Projects() {

    const { i18n, t } = useTranslation();

    return <div className="projects__main">
                <h1>Projects</h1>

                <div className="projects__container">

                    
                    <div className="projects__desc-container">

                        <a href="https://www.ericjlrichard.com/abc" target="blank">
                        <div className="projects__screenshot">
                            <img className="projects__screenshot_image" src={abcScreenshot}></img>
                        </div>
                        </a>

                        <div className="projects__desc">
                            <a href="https://www.ericjlrichard.com/abc" target="blank">
                            <div className="projects__title">
                                {t("projects_abc")}
                            </div>
                            </a>

                            <p>{t("projects1_a")} <a className="inline-link" href="https://www.ericjlrichard.com/abc" target="blank">{t("projects_abc")}</a>{t("projects1_b")}</p>

                            <p><b>{t("techStack")}:</b> {t("projects1_c")}</p>

                            <p>{t("projects1_d")}</p>

                            <p><b>{t("projects_fun_fact")}</b> {t("projects1_e")}</p>
                        </div>
                    </div>
                    
                        
                </div>

                <div className="projects__container">
                    
                    <div className="projects__desc-container">
                        
                        <a href="https://www.ericjlrichard.com/quordle" target="blank">
                        <div className="projects__screenshot">
                            <img className="projects__screenshot_image" src={quordleScreenshot}></img>
                        </div>
                        </a>

                        <div className="projects__desc">
                        
                        <a href="https://www.ericjlrichard.com/quordle" target="blank">
                        <div className="projects__title">
                            {t("projects_quordle")}!
                        </div>
                        </a>

                            <p>{t("projects2_a")} <a href="https://www.ericjlrichard.com/quordle" target="blank" className="inline-link">{t("projects_quordle")}</a>{t("projects2_b")}</p>

                            <p><b>{t("techStack")}:</b> {t("projects2_c")}</p>

                            <p>{t("projects2_d")}</p>

                            <p><b>{t("projects_fun_fact")}</b> {t("projects2_e")}</p>
                        </div>
                    </div>

                </div>
 
            </div>
}