import { Link, NavLink } from "react-router-dom";

import "./Home.scss";

import {useTranslation} from "react-i18next";

export default function Home() {

    const { i18n, t } = useTranslation();

    return <div className="home">
                <div className="home__text">
                <p className="home__p">
                {t("home1")}
            </p>

            <p className="home__p">
                {t("home2_a")} <NavLink className="inline-link" to="About"> {t("home2_l1")}</NavLink>{t("home2_b")} <NavLink className="inline-link" to="Projects">{t("home2_l2")}</NavLink> {t("home2_c")}  <NavLink className="inline-link" to="Resume"> {t("home2_l3")}</NavLink>!
            </p>

            <p className="home__p">
            {t("home3_a")} <NavLink className="inline-link" to="TechStack">{t("home3_l1")}</NavLink>{t("home3_b")}
            </p>

            <p className="home__p">
            {t("home4_a")} <NavLink className='inline-link' to='mailto:ericjlrichard@gmail.com'>{t("home4_l1")}</NavLink>!
            </p>

                </div>
             
            <div className="home__menu">
                <NavLink className="home__link" to="/projects">{t("projects")}</NavLink>
                <NavLink className="home__link" to="/about">{t("about")}</NavLink>
                <NavLink className="home__link" to="/techstack">{t("techStack")}</NavLink>
                <NavLink className="home__link" to="/">{t("resume")}</NavLink>
                <div className="home__divider"></div>
                <NavLink className="home__tinylink" to="https://www.ericjlrichard.com/abc" target="_blank">{t("projects_abc")}</NavLink>
                <NavLink className="home__tinylink" to="https://www.ericjlrichard.com/quordle" target="_blank">Quordle</NavLink>

            </div>

            
                
        </div>
}